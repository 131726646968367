//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GlobalMixin from '../../../shared/GlobalMixins.js';
import GamePlayMixin from "../../../shared/GamePlayMixin";

export default {
  name: "Modal",
  mixins: [GlobalMixin, GamePlayMixin],
  computed: {
    backgroundImage () {
      if (this.modalMessage.backgroundImage !== undefined) {
        return this.modalMessage.backgroundImage
      }

      if (this.assets.images.modal !== undefined) {
        return this.assets.images.modal
      }

      return ''
    }
  }
}
