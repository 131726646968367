//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import GlobalMixin from '../shared/GlobalMixins.js'
import GamePlayMixin from '../shared/GamePlayMixin.js'
import Modal from '../components/GamePlay/Common/Modal'
import Dialog from 'quasar/src/plugins/Dialog.js';;

console.log('MainWrapper', Dialog)

export default {
  name: 'MainWrapper',
  mixins: [GlobalMixin, GamePlayMixin],
  components: {
    Modal,
    AnimatedNumber: () => import(/* webpackChunkName: "animated-number" */ 'animated-number-vue')
   },
  data () {
    return {
      leftDrawerOpen: false,
      showMissionModeModal: false,
      showLogoutModal: false,
    }
  },
  methods: {
    openModal() {
      this.showLogoutModal = true;
    },
    closeModal() {
      this.showLogoutModal = false;
    },
    confirmModal() {
      this.closeModal();
      this.$router.push({ name: 'logout' });
    },
    cancelModal() {
      this.closeModal();
    }
  },
  created () {
    if (this.token !== null && this.loggedIn === false) {
      // this.loggedIn = true
    }
  },
  mounted () {
    // disable right click everywhere but local and staging
    document.addEventListener('contextmenu', function(event) {
      let allowedDomains = ['staging.somedomain.com', 'localhost', 'incrementalhealth.test']; // List of allowed domains

      // Get the hostname of the current page
      let currentDomain = window.location.hostname;

      // Check if the current domain is in the allowed domains list
      if (!allowedDomains.includes(currentDomain) ) {
        event.preventDefault(); // Prevent the default right-click behavior
      }
    });

    // disable CTRL+R
    document.addEventListener('keydown', function(event) {
      if ((event.ctrlKey || event.metaKey) && event.key === 'r') {
        event.preventDefault(); // Prevent the default behavior of the Ctrl+R or Command+R key combination
        // Run your custom functionality here
        console.log('Ctrl+R or Command+R is disabled');
      }
    });

  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // If the navigation wasn't triggered by a Vue Router push/replace (i.e., direct page load or browser back/forward)
      if (!vm.$router.history._popped) {
        vm.getUserStats();
        console.log('got user stats')
      }
      vm.$router.history._popped = false;  // Reset the flag
    });
  }
}
